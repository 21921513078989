.container {
  position: absolute;
  top: 25%;
  width: 100%;
  text-align: center;
}

.spacing {
  margin-top: 32px;
}

.copyright {
  position: absolute;
  bottom: 16px;
  width: 100%;
  text-align: center;
}
