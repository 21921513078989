.diary__container {
  margin: 0 auto;
  padding: 16px;
  max-width: 960px;
}

.diary__table-container {
  height: 70vh;
}

@media (min-width: 760px) {
  .diary__table-container {
    height: 75vh;
  }
}

.diary__table-header {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: auto auto;
  margin-bottom: 16px;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

@media (min-width: 760px) {
  .diary__table-header {
    grid-auto-flow: column;
    justify-content: start;
    margin-bottom: 16px;
    column-gap: 16px;
  }
}

.diary__table-header__info {
  grid-column: 1 / 3;
  position: relative;
  top: 3px;
  display: inline-block;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
}

@media (min-width: 760px) {
  .diary__table-header__info {
    grid-column: initial;
  }
}

.diary__table-header__important {
  font-weight: bold;
}

.diary__table-head {
  color: white !important;
  text-transform: uppercase !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 18px !important;
  padding: 10px !important;
}

.diary__table-row {
  cursor: pointer !important;
  text-transform: uppercase !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 16px !important;
  padding: 8px !important;
}

.dialog__header {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  justify-items: center;
  margin-bottom: 24px;
}

@media (min-width: 760px) {
  .dialog__header {
    display: flex;
  }
}

.dialog__content {
  display: grid;
  row-gap: 16px;
  justify-items: stretch;
}

@media (min-width: 760px) {
  .dialog__content {
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }
}
