.container {
  position: absolute;
  top: 25%;
  width: 100%;
  text-align: center;
}

.alert-block {
  margin: 32px auto;
  width: 250px;
}
