.clients__container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 280px auto;
  height: auto;
}

@media (max-width: 760px) {
  .clients__container {
    grid-auto-flow: row;
    grid-template-columns: auto;
    grid-template-rows: auto;
    height: auto;
    justify-items: center;
    align-items: center;
  }
}

.clients__table-paper {
  max-width: auto;
}

.clients__table-container {
  height: 85vh;
}

@media (min-width: 760px) {
  .clients__table-container {
    height: 85vh;
  }
}

.clients__left-column {
  padding: 16px 16px 16px 32px;
  max-width: 280px;
}

.clients__right-column {
  padding: 16px 32px 16px 16px;
  overflow: hidden;
}

@media (max-width: 760px) {
  .clients__right-column {
    padding: 0 16px 0 16px;
  }
}

.clients__center-column {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}

.clients__drawer-container {
  width: 240px;
  padding: 16px;
}

.clients__table-info {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  margin-bottom: 12px;
}

@media (max-width: 760px) {
  .clients__table-info {
    text-align: center;
  }
}

.clients__table-info--important {
  font-weight: bold;
}

.clients__list-item {
  padding: 0 !important;
}

.clients__reset-filters-button {
  margin-top: 16px !important;
}

@media (min-width: 760px) {
  .clients__add-custom-client-button {
    margin-top: 16px !important;
  }
}

.clients__select-input {
  min-width: 180px;
  margin-top: 16px !important;
}

.indicadorRounded {
  display: inline-block;
  background-color: #1eb6e5;
  border-radius: 15px;
  color: white;
  padding: 4px 6px;
  margin: 2px;
  font-size: 0.8rem;
}

@media (min-width: 760px) {
  .clients__filters_wrapper {
    margin-top: 16px;
    height: 70vh;
    overflow-y: scroll;
  }
}