.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 760px) {
  .header {
    margin: 16px;
  }
}

.header__drawer {
  width: 220px;
}

.header__logo {
  height: 32px;
}

.header__url {
  display: inline-block;
  position: relative;
  top: 6px;
  left: 10px;
  margin-right: 8px;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 8px;
  background-color: transparent;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.header__url:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (min-width: 760px) {
  .header__url.active {
    background-color: rgba(30, 182, 229, 0.08);
  }
}

.header__url.active:hover {
  cursor: default;
}
