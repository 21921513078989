/* CONTENT */
.client-file-content {
  max-width: 1080px;
}

/* HEADER */
.client-file-header__header {
  display: flex;
  justify-content: space-between;
  margin: 16px;
}

.client-file-header__drawer {
  padding: 16px;
  display: grid;
  grid-auto-flow: row;
  row-gap: 12px;
}

.client-file-header__logo {
  height: 32px;
}

.client-file-header__options-row {
  display: flex;
  margin: 16px;
}

.client-file-header__options-row button {
  margin-right: 12px;
}

.client-file-header__options-row button:last-child {
  margin-right: 0;
}

@media print {
  .client-file-header__header {
    display: none;
  }

  .client-file-header__options-row {
    display: none;
  }
}

@page {
  size: landscape;
}

/* INFO */
.client-file-info {
  user-select: text !important;
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin: 16px;
}

@media (max-width: 760px) {
  .client-file-info {
    grid-template-columns: auto;
  }
}

.client-file-info__title {
  grid-column: 1 / -1;
}

.client-file-info__block {
  display: grid;
  grid-gap: 8px;
  margin-top: 24px;
  margin-bottom: 12px;
}

@media (max-width: 760px) {
  .client-file-info__block {
    grid-template-columns: auto;
    margin-top: 8px;
    margin-bottom: 0px;
  }
}

.client-file-info__description {
  user-select: none !important;
  color: rgba(30, 182, 229, 0.5) !important;
  text-transform: uppercase !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 18px !important;
}

.client-file-info__info {
  text-transform: uppercase !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 18px !important;
}

.client-file-info__separator {
  margin: 16px;
  border-bottom: none;
}

/* INFO */
.client-file-tags {
  user-select: text !important;
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  column-gap: 16px;
}

@media (max-width: 760px) {
  .client-file-tags {
    grid-template-columns: auto;
    row-gap: 16px;
  }
}

.client-file-tags__block {
  display: grid;
  grid-gap: 16px;
  justify-content: center;
}

.client-file-tags__block__title {
  user-select: none !important;
  color: rgba(30, 182, 229, 0.5) !important;
  text-transform: uppercase !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 18px !important;
  text-align: center;
  margin-bottom: 16px;
}

.client-file-tags__block__tags-list {
  text-align: center;
}

@media (max-width: 760px) {
  .client-file-tags__block__tags-list {
    grid-template-columns: repeat(2, auto);
  }
}

.client-file-tags__block__tag {
  display: inline-block;
  user-select: none !important;
  text-transform: uppercase !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 18px !important;
  background: rgba(30, 182, 229, 0.15);
  border-radius: 15px;
  padding: 8px;
  text-align: center;
  margin: 4px;
}
